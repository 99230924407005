.hub-sort *,
input {
  outline: none;
}

.hub-sort *,
button {
  outline: none;
}

.hub-sort *,
button > svg {
  margin-right: -0.35em !important;
}
