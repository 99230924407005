.hub-userlogo-size {
  width: 1.5em;
  height: 1.5em;
}

.hub-userProfile {
  margin-right: -1em;
  outline: none;
}

.hub-userProfile svg {
  margin-left: -1em;
  margin-right: 0em;
}

.hub-userProfile-modal {
  width: 50%;
}

.hub-userProfile a {
  outline: none;
}
