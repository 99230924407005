.hub-description {
  margin: auto;
  margin-top: 2em;
  max-width: 110em;
}

.hub-description-header {
  padding-top: 2em;
}

.hub-tabs {
  width: 90em;
  background: white;
}

.hub-horizontal-line {
  background-color: #ededed;
  margin-bottom: 1em;
}

.hub-readme > h2:first-child,
.hub-readme > h1:first-child {
  font-weight: bold;
  font-size: 1.3em;
  padding-bottom: 0.5em;
}

.hub-readme > h2,
h3 {
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.hub-readme > h2:first-child {
  padding-top: 0em;
  padding-bottom: 0.5em;
}
.hub-readme > ul {
  list-style-type: disc;
  padding-left: 2em;
}
.hub-readme > ol {
  padding-left: 2em;
  font-family: Helvetica;
  padding-bottom: 0.5em;
}
.hub-readme > ul > li {
  padding-bottom: 0.5em;
}
.hub-readme > p {
  padding-bottom: 0.5em;
}
.hub-readme {
  font-family: Helvetica;
}

.hub-readme > table,
tr,
th {
  text-align: center !important;
  border: 1px solid black;
  padding: 1em 1em 1em 1em;
}

.hub-readme > table,
th,
td {
  border: 1px solid black;
  text-align: justify;
  padding: 1em 1em 1em 1em;
}

.hub-readme > pre {
  background: rgb(245, 242, 240);
  text-shadow: white 0px 1px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  overflow: auto;
}

.hub-readme > pre > code {
  color: black;
  font-size: 1em;
}

.hub-readme > ul > li > pre {
  color: black;
  background: rgb(245, 242, 240);
  text-shadow: white 0px 1px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  overflow: auto;
}

.hub-readme > ul > li > pre > code {
  color: black;
  font-size: 1em;
}

.hub-readme > div > pre {
  color: black;
  background: rgb(245, 242, 240);
  text-shadow: white 0px 1px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  overflow: auto;
}

pre > div > code {
  color: black;
  background: none;
  text-shadow: white 0px 1px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

code {
  font-family: Consolas, 'courier new';
  font-weight: bold;
  color: crimson;
  padding: 2px;
  font-size: 100%;
}

.a > code {
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 90%;
}

.hub-details-spinner {
  position: fixed;
  left: 48%;
  right: 0;
  top: 2em;
  bottom: 0;
  margin: auto;
}
